<template>
  <div class="interior-join-offer-wrapper flex flex-row items-center">
    <div class="interior-join-offer-item h-full flex-1 flex flex-col justify-center">
      <h3 class="mb-4">
        {{ data.hasInteriorOffer.address }}
        {{ data.hasInteriorOffer.addressDetail }}
      </h3>

      <div>
        <p class="place">
          {{ data.price.toLocaleString() }}원
          <span>(임차제안금)</span>
        </p>
        <p class="date">{{ date }} <span>(임차신청일)</span></p>
      </div>
    </div>

    <div class="button-group">
      <button
        @click="() => onDetail(data)"
        class="flex justify-center items-center"
      >
        {{ buttonText }}
      </button>

      <button
        @click="goToReview"
        v-if="data.isConfirm && canReview && isReview"
        class="flex justify-center items-center mt-2 disable"
      >
        후기작성
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "InteriorJoinOfferItem",
  props: {
    data: Object,
    isHistory: {
      type: Boolean,
      default: false,
    },
    onDetail: {
      type: Function,
      default: () => {},
    },
    isReview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    date() {
      return dayjs(this.data.hasInteriorOffer.createdAt).format("YYYY-MM-DD");
    },
    buttonText() {
      if (this.isHistory) {
        return "자세히보기";
      }
      return "승인됨";
    },
    canReview() {
      const { hasTradeReviews } = this.data;
      return (
        hasTradeReviews.filter((item) => item.userId === this.userId).length ===
        0
      );
    },
    ...mapState("user", ["userId"]),
  },
  methods: {
    goToReview() {
      this.$router.push({
        name: "InteriorReviewCreate",
        params: {
          id: this.data.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.interior-join-offer-wrapper {
  height: 185px;
  border-bottom: 1px solid #eeeeee;

  h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;
    margin-top: 20px;
  }

  .place {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #737373;
  }

  .date {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #616161;
    margin-bottom: 20px;

    span {
      font-weight: normal;
    }
  }

  .button-group {
    button {
      width: 135px;
      height: 46px;
      border-radius: 10px;
      background-color: #283aef;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.16px;
      text-align: left;
      color: #fff;

      &.disable {
        background-color: #bfbfbf;
      }
    }
  }
}

@media (max-width: 639px) {
  .interior-join-offer-wrapper {
    height: auto;
    width: 100%;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    .interior-join-offer-item {
      width: 100%;
    }
    h3 {
      margin-top: 0;
      font-size: 13px;
    }
    .place {
      font-size: 12px;
    }
    .date {
      font-size: 12px;
      margin-bottom: 15px;
    }
    .button-group {
      width: 100%;
      max-width: 320px;
      button {
        width: 100%;
        font-size: 13px;
        height: 35px;
      }
    }
  }
}
</style>
