<template>
  <div
    :class="[
      'tab-wrapper',
      'flex',
      'flex-row',
      'items-center',
      { 'tab-border': hasBorder },
    ]"
  >
    <div
      v-for="(item, index) in tabs"
      :key="index"
      @click="() => onTab(index)"
      :class="[
        'item',
        'flex',
        'items-center',
        'justify-center',
        {
          active: tabIndex === index,
          activeBorder: tabIndex === index && hasBorder,
        },
      ]"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TabLayout",
  props: {
    tabs: Array,
    tabIndex: Number,
    onTab: Function,
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.tab-wrapper {
  height: 60px;

  &.tab-border {
    border-bottom: 1px solid #eeeeee;
  }

  .item {
    cursor: pointer;
    width: 90px;
    height: 60px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #cacaca;

    &.active {
      color: #283aef;
    }

    &.activeBorder {
      border-bottom: 2px solid #283aef;
    }
  }
}

@media (max-width: 639px) {
  .tab-wrapper {
    height: 35px;
    .item {
      height: 100%;
      font-size: 12px;
    }
  }
}
</style>
