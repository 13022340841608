<template>
  <div
    class="flex flex-row items-center justify-between place-offer-item-wrapper"
  >
    <div class="place-offer-item flex flex-1 h-full">
      <PlaceSearchItem
        :data="data.hasPlace"
        :hasInfo="false"
        :isOffer="true"
        :hasDate="true"
        :price="data.price"
      />
    </div>

    <div class="flex flex-col button-group">
      <button v-if="!data.isConfirm" class="flex justify-center items-center">
        신청중
      </button>
      <button
        v-if="data.isConfirm"
        @click="() => onDetail(data)"
        class="flex justify-center items-center active"
      >
        자세히보기
      </button>
      <!-- <button
        @click="goToReview"
        v-if="data.isConfirm && canReview && isReview"
        class="flex justify-center items-center"
      >
        후기작성
      </button> -->
    </div>
  </div>
</template>

<script>
import PlaceSearchItem from "@/components/PlaceSearchItem.vue";
import { mapState } from "vuex";

export default {
  name: "PlaceOfferItem",
  components: { PlaceSearchItem },
  props: {
    data: Object,
    onDetail: {
      type: Function,
      default: () => {},
    },
    isReview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canReview() {
      const { hasTradeReviews } = this.data;
      return (
        hasTradeReviews.filter((item) => item.userId === this.userId).length ===
        0
      );
    },
    ...mapState("user", ["userId"]),
  },
  methods: {
    goToReview() {
      this.$router.push({
        name: "PlaceReviewCreate",
        params: {
          id: this.data.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.place-offer-item-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
}

.button-group {
  button {
    width: 135px;
    height: 46px;
    border-radius: 10px;
    background-color: #bfbfbf;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: left;
    color: #fff;

    &:not(:last-child) {
      margin-bottom: 9px;
    }

    &.active {
      background-color: #293bf0;
    }
  }
}

@media (max-width: 639px) {
  .place-offer-item-wrapper {
    width: 100%;
    flex-direction: column;
    .place-offer-item {
      width: 100%;
    }
  }
  .button-group {
    width: 100%;
    max-width: 320px;
    button {
      width: 100%;
      font-size: 13px;
      height: 35px;
    }
  }
}
</style>
