var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'tab-wrapper',
    'flex',
    'flex-row',
    'items-center',
    { 'tab-border': _vm.hasBorder } ]},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:[
      'item',
      'flex',
      'items-center',
      'justify-center',
      {
        active: _vm.tabIndex === index,
        activeBorder: _vm.tabIndex === index && _vm.hasBorder,
      } ],on:{"click":function () { return _vm.onTab(index); }}},[_vm._v(" "+_vm._s(item)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }